import { getTokenId, removeTokenID } from "src/store/persistedHelper";
import params from "src/config/params.json";
import * as firebase from "firebase/app";

import { getAuth, updatePassword } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";

import { apiAuth, api } from "@/utils/api";

const config = {
  apiKey: params.REACT_APP_API_KEY,
  authDomain: params.REACT_APP_AUTH_DOMAIN,
  databaseURL: params.REACT_APP_DATABASE_URL,
  projectId: params.REACT_APP_PROJECT_ID,
  appId: params.REACT_APP_ID,
};

const app = firebase.initializeApp(config);

export const auth = getAuth();

export const getDb = () => getFirestore(app);

export default firebase;

export const register = async (paramsUser) => {
  try {
    const response = await api.post("/Firebase/createUser", paramsUser);
    return await response.json();
  } catch (error) {
    console.error("Error when sign up with email => ", error);
  }
};

export const login = async ({ email, password }) => {
  try {
    const response = await api.post("/Firebase/signin", {
      email,
      password: encodeURIComponent(password),
    });
    const resultJson = await response.json();
    return resultJson;
  } catch (error) {
    console.error("Error when sign in with email => ", error);
  }
};

export const doSignInGoogle = async (email, password) => {
  const providerGoogle = new auth.GoogleAuthProvider();
  if (providerGoogle)
    providerGoogle.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
  // const response = await fetch(`${API.url}/API/Firebase/getGoogleProvider`);
  // const { provider: providerGoogle } = (await response.json()) || {};
  return auth.signInWithRedirect(providerGoogle);
};

export const createUserFromRedirect = async (data) => {
  apiAuth.post("/Firebase/createUserFromRedirect", data);
};

export const getAuthGoogle = async () => {
  const { idToken, credential } = await auth.getRedirectResult();
  const userIdToken = credential?.idToken || idToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    token: userIdToken,
  };
  const user = await api.post("/Firebase/signinGoogle", {
    headers,
  });
  return user;
};

export const getUserById = async (userId) => {
  try {
    const userData = await apiAuth.get(
      "/Firebase/Auth/getUserById?userId=" + userId
    );
    return userData.json();
  } catch (error) {
    console.error("Error while fetching user : ", error);
  }
};

export const requestForgetPassword = async (email) => {
  try {
    const resultForgetPassword = await api.post(
      "/Firebase/forgetPassword/",
      { email },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("result", await resultForgetPassword.json());
  } catch (error) {
    console.log("error", error);
  }
};

export const getMe = async () => {
  if (!getTokenId()) {
    console.error("No token id found");
    return;
  }
  try {
    const response = await apiAuth.get("/Firebase/getme");
    const userData = await response.json();
    console.log("results sign with token", userData);
    if (userData.code === "auth/id-token-expired") {
      removeTokenID();
      return null;
    }
    return userData;
  } catch (error) {
    console.error("Error when sign in with token => ", error);
  }
};

// export const doSignOut = () => auth.signOut();
export const doSignOut = () => {
  removeTokenID();
};
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);
export const changePassword = async (password) =>
  updatePassword(auth.currentUser, password);

export const updateUser = (data) => {
  const db = getDb();
  const userDocRef = doc(db, "users", auth.currentUser?.uid);
  setDoc(userDocRef, data, { merge: true })
    .then(() => {
      console.info("successfully written to db");
    })
    .catch((err) => console.log(err));
};

export const updateUserProfile = async (data) => {
  try {
    const resp = await apiAuth.post("/Firebase/updateme", data);
    const { user } = await resp.json();
    return user;
  } catch (error) {
    console.error("Error when updating user profile => ", error);
  }
};

export const generateEmailLink = async ({email}) => {
  const emailSend = await apiAuth.post("/Firebase/generateEmailLink", {
    email,
    redirectUrl: `${window.location}`,
  });
  // const link = await emailSend.json();
  console.log("emailSend",emailSend);
  // return link;
};
