import { Button } from "@/components/ui/button";
import Stripe from "stripe";
import { usePaginatedRides } from "./hooks/usePaginateRides";
import { Fragment } from "react";
// import { useTranslation } from "react-i18next";
import { ListItemRide } from "@/components/Profile/history/components/ListItemRide";

export const HistorySection = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedRides();
  // const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-1">
      <div>
        <h3 className="text-lg font-semibold mb-2">Payment Methods</h3>
        <div className="flex items-center justify-between bg-gray-100 p-3 rounded">
          <span>•••• •••• •••• 1234</span>
          <Button variant="ghost">Edit</Button>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <h3 className="text-lg font-semibold mb-2">Recent Trips</h3>
        <div className="overflow-auto flex-1 max-h-[400px]">
          <ul className="space-y-2">
            {(data?.pages || []).map((page, pageIndex) => {
              return (
                <Fragment key={pageIndex}>
                  {page?.paymentIntentsList.map(
                    (ride: Stripe.Checkout.Session) => {
                      return <ListItemRide key={ride.id} ride={ride} />;
                    }
                  )}
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      <Button
        className="w-full md:w-auto"
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        {isFetchingNextPage
          ? "Loading more..."
          : hasNextPage
          ? "Load More"
          : "Nothing more to load"}
      </Button>
    </div>
  );
};
