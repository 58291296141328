import { Link, Outlet, useLocation } from "react-router-dom";

import { Navbar, Nav } from "react-bootstrap";
import Navigation from "../Navigation";
import AccountComponent from "../Account";

import * as ROUTES from "../../constants/routes";
/*
Context:
  userContext with state and dispatch for update currentUser and information from firebase
  useAuth : get firebase currentUser
*/

import LanguageSelector from "../LanguageSelector";
import SwiperHome from "@vc-components/Home/Swiper/SwiperHome";
import WinningCourse from "@vc-components/Home/WinningCourse/WinningCourseComponent";

import "./styles.scss";
import FooterComponent from "@vc-components/Home/FooterHome/FooterHome";

const MainContent = () => {
  const { pathname } = useLocation();
  return (
    <div className="main-container relative lg:snap-mandatory md:snap-proximity overflow-y-auto h-screen snap-y">
      <div className="first-section backscreen pd-h-lg lg:snap-end h-screen lg:overflow-hidden">
        <div className="h-full pb-2 flex flex-col">
          {renderHeader()}
          <div className="body-section flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      {pathname === ROUTES.HOME && (
        <>
          <SwiperHome />
          <WinningCourse
            title={"Vroomcab, votre trajet gagnant"}
            titleStyle="f-color-darkgrey slide-big-title"
            containerStyle="inline-center snap-start min-h-screen"
          />
          <FooterComponent />
        </>
      )}
    </div>
  );
};

const renderHeader = () => (
  <header className="container-header">
    <Navbar variant="dark" className="header nav container" expand="lg">
      <Navbar.Brand className="v-logo text-white">
        <Link to={ROUTES.HOME}>
          <span style={{ color: "#fff" }}>vroom</span>
          <span className="f-red">cab</span>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="v-toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="nav">
          <AccountComponent />
          <Navigation />
          <LanguageSelector />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
);

export default MainContent;
