import { SidebarNav } from "@/components/Profile/subComponents/sidebar-nav";
import * as ROUTES from "@/constants/routes";
import { Outlet } from "react-router";

const sidebarNavItems = [
  {
    title: "Profile",
    href: ROUTES.PROFILE,
  },
  {
    title: "Account",
    href: ROUTES.ACCOUNT,
  },
  {
    title: "History",
    href: ROUTES.HISTORY,
  },
];

export default function SettingsLayout() {
  return (
    <>
      <div className="space-y-6 p-10 pb-16 md:block md:w-full h-full relative lg:overflow-hidden bg-white">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <hr className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="lg:max-w-2xl lg:overflow-hidden flex flex-col flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
