"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/context/auth";
import { useEffect } from "react";
import { updateUser } from "@/firebase/firebase";

const profileFormSchema = z.object({
  username: z
    .string()
    .min(2, {
      message: "Username must be at least 2 characters.",
    })
    .max(30, {
      message: "Username must not be longer than 30 characters.",
    }),
  fullname: z.string(),
  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .email(),
  phoneNumber: z.string(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<ProfileFormValues> = {
  email: "m@example.com",
  username: "",
  fullname: "",
  phoneNumber: "",
};

export function ProfileForm() {
  const { userData } = useAuth();

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onSubmit",
  });

  useEffect(() => {
    if (userData) {
      form.reset({
        email: userData?.email,
        username: userData?.displayName,
        fullname: userData?.fullname,
        phoneNumber: userData?.phoneNumber || "",
      });
    }
  }, [userData]);

  function onSubmit(data: ProfileFormValues) {
    // TODO: faire en sorte d'update l'user dans db et dans le state global
    updateUser(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex-1 overflow-auto space-y-2"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="John" {...field} />
              </FormControl>
              <FormDescription className="text-xs">
                This is your public display name. It can be your real name or a
                pseudonym. You can only change this once every 30 days.
              </FormDescription>
              <FormMessage className="text-red-500 error" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fullname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nom complet</FormLabel>
              <FormControl>
                <Input placeholder="John Doe" {...field} />
              </FormControl>
              <FormDescription className="text-xs">
                This is your full name as it will appear on your profile and in
                your invoices or emails
              </FormDescription>
              <FormMessage className="text-red-500 error" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="phoneNumber">Numéro de téléphone</FormLabel>
              <FormControl>
                <Input
                  id="phoneNumber"
                  type="tel"
                  placeholder="Numéro de téléphone"
                  {...field}
                />
              </FormControl>
              <FormDescription></FormDescription>
              <FormMessage className="text-red-500 error" />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={!form.formState.isDirty}>
          Mettre à jour le profile
        </Button>
      </form>
    </Form>
  );
}
