import { api, apiAuth } from "@/utils/api";
import {
  login,
  register,
  updateUser as updateUserService,
  requestForgetPassword,
  changePassword
} from "../firebase/firebase";
import { UserSignUp, UserLogin, User } from "src/constants/types/User";

// call function api firebase or API
// export const getAuthSignIn = (
//   userId: string | null | undefined,
//   callback: (e: object) => void
// ) => {
//   getDb()
//     .ref(`/users/${userId}`)
//     .on("value", (user) => {
//       let reponse = user.val();
//       console.log(user.toJSON());
//       reponse ? callback(user.val()) : callback({ username: "" });
//     });
// };

export const signIn = async (params: UserLogin) => {
  return await login(params);
};

export const signUp = async (params: UserSignUp) => {
  return await register(params);
};

export const getUserData = async ({ userId }: User) => {
  const url = `/Firebase/getme/${userId}`;

  try {
    const { data: users } = await apiAuth.get(url, { responseType: "json" });
    return users;
  } catch (error) {
    console.error("error on fetch user data", error);
    return null;
  }
};

export const getAllUsers = async () => {
  const { data: users } = await api.get("/Firebase/getAllUsers");
  return users;
};

export const updateUser = async (course: any) => {
  updateUserService(course);
};

export const requestForgetPwd = async (email: string) =>
  requestForgetPassword(email);

export const updateProfile = async (user: any) => {};

export const updatePassword = async (password: any) => {
  changePassword(password);
};
